var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-card",
                { staticClass: "card", attrs: { bordered: false } },
                [
                  _c(
                    "a-row",
                    { staticClass: "form-row", attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务名" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "task.name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入任务名称",
                                            whitespace: true,
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[ 'task.name', {rules: [{ required: true, message: '请输入任务名称', whitespace: true}]} ]",
                                  },
                                ],
                                attrs: { placeholder: "请输入任务名称" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务描述" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "task.description",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入任务描述",
                                            whitespace: true,
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['task.description', {rules: [{ required: true, message: '请输入任务描述', whitespace: true}]} ]",
                                  },
                                ],
                                attrs: { placeholder: "请输入任务描述" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "执行人" } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "task.executor",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择执行人",
                                            },
                                          ],
                                        },
                                      ],
                                      expression:
                                        "['task.executor',{rules: [{ required: true, message: '请选择执行人'}]}  ]",
                                    },
                                  ],
                                  attrs: { placeholder: "请选择执行人" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "黄丽丽" } },
                                    [_vm._v("黄丽丽")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "李大刀" } },
                                    [_vm._v("李大刀")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticClass: "form-row", attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "责任人" } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "task.manager",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择责任人",
                                            },
                                          ],
                                        },
                                      ],
                                      expression:
                                        "['task.manager',  {rules: [{ required: true, message: '请选择责任人'}]} ]",
                                    },
                                  ],
                                  attrs: { placeholder: "请选择责任人" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "王伟" } },
                                    [_vm._v("王伟")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "李红军" } },
                                    [_vm._v("李红军")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "提醒时间" } },
                            [
                              _c("a-time-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "task.time",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "请选择提醒时间",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['task.time', {rules: [{ required: true, message: '请选择提醒时间'}]} ]",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { lg: 8 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "任务类型" } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "task.type",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择任务类型",
                                            },
                                          ],
                                        },
                                      ],
                                      expression:
                                        "['task.type', {rules: [{ required: true, message: '请选择任务类型'}]} ]",
                                    },
                                  ],
                                  attrs: { placeholder: "请选择任务类型" },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "定时执行" } },
                                    [_vm._v("定时执行")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "周期执行" } },
                                    [_vm._v("周期执行")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-tabs",
                { attrs: { defaultActiveKey: "1" } },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "Tab 1" } },
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.columns,
                          dataSource: _vm.data,
                          pagination: false,
                          size: "middle",
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm._l(
                              ["name", "workId", "department"],
                              function (col, i) {
                                return {
                                  key: col,
                                  fn: function (text, record, index) {
                                    return [
                                      _c(
                                        "a-tooltip",
                                        {
                                          attrs: {
                                            title: "必填项",
                                            defaultVisible: false,
                                            overlayStyle: "{ color: 'red' }",
                                          },
                                        },
                                        [
                                          record.editable
                                            ? _c("a-input", {
                                                key: col,
                                                staticStyle: {
                                                  margin: "-5px 0",
                                                },
                                                attrs: {
                                                  value: text,
                                                  placeholder:
                                                    _vm.columns[i].title,
                                                },
                                                on: {
                                                  change: (e) =>
                                                    _vm.handlerRowChange(
                                                      e.target.value,
                                                      record.key,
                                                      col
                                                    ),
                                                },
                                              })
                                            : [_vm._v(_vm._s(text))],
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                }
                              }
                            ),
                            {
                              key: "operation",
                              fn: function (text, record, index) {
                                return [
                                  record.editable
                                    ? [
                                        record.isNew
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveRow(
                                                          record.key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("添加")]
                                                ),
                                                _c("a-divider", {
                                                  attrs: { type: "vertical" },
                                                }),
                                                _c(
                                                  "a-popconfirm",
                                                  {
                                                    attrs: {
                                                      title: "是否要删除此行？",
                                                    },
                                                    on: {
                                                      confirm: function (
                                                        $event
                                                      ) {
                                                        return _vm.removeRow(
                                                          record.key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_c("a", [_vm._v("删除")])]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveRow(
                                                          record.key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("保存")]
                                                ),
                                                _c("a-divider", {
                                                  attrs: { type: "vertical" },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.cancelEditRow(
                                                          record.key
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("取消")]
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editRow(record.key)
                                                },
                                              },
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c("a-divider", {
                                            attrs: { type: "vertical" },
                                          }),
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                title: "是否要删除此行？",
                                              },
                                              on: {
                                                confirm: function ($event) {
                                                  return _vm.removeRow(
                                                    record.key
                                                  )
                                                },
                                              },
                                            },
                                            [_c("a", [_vm._v("删除")])]
                                          ),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            width: "100%",
                            "margin-top": "16px",
                            "margin-bottom": "8px",
                          },
                          attrs: { type: "dashed", icon: "plus" },
                          on: { click: _vm.newRow },
                        },
                        [_vm._v("新增成员")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "Tab 2", forceRender: "" } },
                    [_vm._v("\n          Content of Tab Pane 2\n        ")]
                  ),
                  _c("a-tab-pane", { key: "3", attrs: { tab: "Tab 3" } }, [
                    _vm._v("Content of Tab Pane 3"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }